export const tableColumns = [
	{
		colType: 'text',
		model: 'TerritoryId',
		i18n: 'id2541',
		sortable: true,
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		colType: 'link',
		model: 'TerritoryNameSk',
		i18n: 'namesk',
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryNameLokalSk',
		i18n: 'localsk',
		sortable: true,
	},
	{
		colType: 'link',
		model: 'TerritoryNameCz',
		i18n: 'namecz',
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryNameLokalCz',
		i18n: 'localcz',
		sortable: true,
	},
	{
		colType: 'link',
		model: 'TerritoryNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryNameLokalPl',
		i18n: 'localpl',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'link',
		model: 'TerritoryNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryNameLokalEn',
		i18n: 'locallen',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'link',
		model: 'TerritoryNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryNameLokalDe',
		i18n: 'localde',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'link',
		model: 'TerritoryNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryNameLokalHu',
		i18n: 'localhu',
		hidden: true,
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryUrlName',
		i18n: 'url',
		sortable: true,
	},
	{
		colType: 'text',
		model: 'TerritoryGoogleMapsZoom',
		i18n: 'zoomonmap3264',
		hidden: true,
		sortable: true,
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		colType: 'text',
		model: 'CityCount',
		i18n: 'numberofcities',
		sortable: true,
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
	},
];
