<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="localities3293"
		tooltipMsg="manualforlocali"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'TerritoryId', ascending: 0 }"
		modelId="TerritoryId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	></table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setCellFormatOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import siteUrlBuilder from '@/services/helpers/site-url-builder';

import { tableColumns } from './territory-table.data';

export default {
	name: 'TerritoryTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'TerritoryListItem',
			columns: tableColumns,
		};
	},

	created() {
		this.columns = this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.territoryDetailName,
				params: { TerritoryId: id },
			});
		},

		prepareColumns(columns) {
			const linksModels = [
				'TerritoryNameSk',
				'TerritoryNameCz',
				'TerritoryNamePl',
				'TerritoryNameEn',
				'TerritoryNameDe',
				'TerritoryNameHu',
			];

			let cols = prepareLinkCols(columns, linksModels);

			return cols;

			function prepareLinkCols(columns, linkModels) {
				linkModels.forEach((model) => {
					const cellFormatOptions = {
						props: (list) => ({
							href: list.row[`${model}__Url`],
							target: '_blank',
						}),
					};

					setCellFormatOptions(columns, model, cellFormatOptions);
				});

				return columns;
			}
		},

		transformResponseData(data) {
			data.data = data.data.map((row) => ({
				...row,
				TerritoryNameSk__Url: this.getUrl(row, 'sk'),
				TerritoryNameCz__Url: this.getUrl(row, 'cz'),
				TerritoryNamePl__Url: this.getUrl(row, 'pl'),
				TerritoryNameEn__Url: this.getUrl(row, 'en'),
				TerritoryNameDe__Url: this.getUrl(row, 'de'),
				TerritoryNameHu__Url: this.getUrl(row, 'hu'),
			}));

			return data;
		},

		getUrl(row, lang) {
			return siteUrlBuilder.buildUrl(row.DomainUrl, lang) + '/' + row.TerritoryUrlName;
		},
	},
};
</script>

<style lang="scss">
</style>
